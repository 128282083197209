<template>
  <div class="projectGradeBox">
    <div
      class="startBox"
      v-if="gradeList.totalPoints && gradeList.totalPoints.score"
    >
      <div class="leftStar">
        <p>总分{{ gradeList.totalPoints.score }}分</p>
        <span>满分5分</span>
      </div>
      <div class="rightStar">
        <StarModel
          color="#fece30"
          :isForbid="isForbid"
          :defaultStar="gradeList.totalPoints.score"
          @change-star="changetotalStar"
        ></StarModel>
      </div>
      <!-- <img class="bgImg" :src="gradeBgImg" /> -->
    </div>
    <ul class="gradeTitleList">
      <p class="tops">以下选项为可选，您可能需要反馈：</p>
      <li v-for="(item, index) in gradeList.questionList" :key="index">
        <div class="titleBox">
          {{ item.title }}
          <span class="startNum">{{item.score}}</span>
          <div class="rightStar" @click="clickStart(item)" v-if="item.score"> 
            <StarModel
              :defaultStar="item.score"
              classes="smallStart"
              color="#fece30"
              :isForbid="isForbid"
              @change-star="changeStar"
            ></StarModel>
          </div>
          
        </div>
        <div class="optBox">
          <span
            :class="opt.isOpt && 'isOpt'"
            @click="changeOpt(opt)"
            v-for="(opt, indexp) in item.options"
            :key="indexp"
            >{{ opt.text }}</span
          >
        </div>
      </li>
    </ul>
    <div class="feedbackBox">
      <p>
        反馈<span
          v-if="
            this.gradeList.totalPoints && this.gradeList.totalPoints.score < 4
          "
          >*</span
        >
      </p>
      <textarea
        placeholder="请输入反馈，总分1-3分请提供建议"
        v-model="description"
        :disabled="isForbid"
       
      ></textarea>
    </div>
    <a
      :class="['submitBtn', isForbid && 'isForbid']"
      @click="!isForbid && submit()"
      >提交</a
    >
  </div>
</template>

<script>
// import Gradebg from './Grade'
export default {
  name: "Grade",
  props: [
    "queryParams", //评分列表
    "isForbidPage", //是否禁止操作
    "itemId",
    "expenseId",
    "proposalId"
  ],
  data() {
    return {
      isForbid:true,
      gradeList: {},
      description: "",
      choiseStart: 5,
      // gradeBgImg: Gradebg    
      };
  },
  mounted() {
    this.isForbid=this.isForbidPage;
    this.getsettings();
    
  },
  components: {},
  methods: {
    getsettings() {
      this.services.getsettings(this.queryParams).then((res) => {
        if (res && res.success) {
          // this.gradeList = res.content.gradeList;
          // this.isForbid&&this.QuerySurvay();
          if(this.isForbid){
            this.QuerySurvay();
            this.tepgradeList=res.content.gradeList;
          }else{
            this.gradeList = res.content.gradeList;
          }
        }
      });
    },
    changetotalStar(data) {
      this.gradeList.totalPoints.score = data;
    },
    changeStar(data) {
      this.choiseStart = data;
    },
    clickStart(data) {
      data.score = this.choiseStart;
    },
    changeOpt(opt) {
      if (this.isForbid) {
        return;
      }
      opt.isOpt = !opt.isOpt;
    },
    getGuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    QuerySurvay() {
      let params = this.expenseId?{
        expenseId: this.expenseId,
      }:{
        ItemId: this.itemId,
      }
      this.services.QuerySurvay(params).then((res) => {
        if (res && res.success) {
          this.tepgradeList.questionList.forEach((ele) => {
            let newData = [];
            let data=ele.options;
            let repData=res.content.questionList.filter(item => item.titleValue== ele.titleValue)[0];
            let replaceData=JSON.parse(repData.items) ;
            for (let i = 0; i < data.length; i++) {
              if (replaceData.some((item) => item.optIndex == data[i].optIndex)) {
                newData.push(replaceData.find((item) => item.optIndex == data[i].optIndex));
              } else {
                newData.push(data[i]);
              }
            }
            ele.options=newData;
            ele.score=repData.score;
            
          });
          this.description=res.content.description;
          this.gradeList=this.tepgradeList;
          this.gradeList.totalPoints.score=res.content.totalScore;
        }
      });
    },
    formatData() {
      let arrayList = [];
      let Options = [];
      this.gradeList.questionList.forEach((element) => {
        element.options.forEach((op) => {
          if (op.isOpt) {
            Options.push(op);
          }
        });
        arrayList.push({
          Title: element.title,
          TitleValue: element.titleValue,
          Score: element.score,
          Options: Options,
        });
        Options = [];
      });
      return arrayList;
    },
    async submit() {
      if (this.gradeList.totalPoints.score < 4 && !this.description) {
        this.$toastBox("总分1-3分请提供建议");
        return false;
      }
      let QuestionnaireId = await this.getGuid();
      let arrayLists = await this.formatData();
      let params = {
        ProposalId: this.proposalId,
        QuestionnaireId: QuestionnaireId,
        TotalScore: this.gradeList.totalPoints.score,
        Description: this.description,
        QuestionList: arrayLists,
      };
      if(this.expenseId){
        params.expenseId=this.expenseId
      }else{
        params.ItemId=this.itemId
      }
      this.services.SaveSurvay(params).then((res) => {
        if (res && res.success) {
          this.$toastBox("提交成功");
          this.isForbid=true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/Grade/grade.scss";
</style>