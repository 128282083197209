<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="projectGradeMian">
      <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant'))?{
          background: '#fff',
          color: '#13161B',
        }: {
          background: 'var(--themeColor)',
          color: '#fff',
        }"
      :backEvent="true"
      @back-event="projectGradeClose()"
      :back="true"
    >
    <div slot="headText">
        <span class="head-txt">
          满意度调查
        </span>
    </div>
    </iHeader>
      <div class="projectGradeBox" v-if="isShowPopup">
        <GradeItem
          :queryParams="queryParams"
          :proposalId="item.proposalId"
          :itemId="item.itemId||item.ItemId"
          :expenseId="item.expenseId"
          :isForbidPage="isForbidPage"
        ></GradeItem>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import GradeItem from "./GradeItem";
export default {
  props: ["refrashData"],
  components: {
    GradeItem
  },
  data() {
    return {
      isForbidPage:true,
      item: {},
      isShowPopup: false,
      queryParams: {
        Collection: "cfg-isurvey",
        filter: {
          tenantCode: "msd",
        },
        projection: {},
        sort: {
          TenantCode: -1,
        },
      },
    };
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners("projectGradeClose"); //保存当前路由返回事件记录
        this.$root.$eventHub.$on("projectGradeClose", (data) => {
          this.projectGradeClose();
          this.$root.$eventHub.$off("projectGradeClose");
        });
      } else {
        this.removeRouteListeners("projectGradeClose"); //返回删除记录
      }
    },
  },
  methods: {
    projectGradeClose() {
      this.isShowPopup = false;
      this.refrashData();
    },
    async showPopup(data, btn) {
      console.log(data);
      console.log(btn);
      this.item = data;
      this.isForbidPage=btn.status == 1 ? true : false
      this.isShowPopup = true;
    },
  },
};
</script>

<style scoped lang="scss">

.popup_box{
   z-index: 99999999999999999;
   top: 0;
}
</style>